import { AxiosRequestConfig } from "axios";
import HTTP, { apiHandler } from "@services/http.service";
import { Image, ListingItem, ServerRequest } from "@lib/shared/types";

interface DomainPopupResponse {
    data: DomainPopup | null;
    error: Error | null;
}

export interface DomainPopup {
    backgroundImage: Image | null;
    backgroundImageId: string;
    description: string;
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    logo: Image | null;
    logoId: string;
    mainImage: Image | null;
    mainImageId: string;
    name: string;
    offer: ListingItem;
    offerId: number;
    showAfter: number;
    features: string[];
}

export const getDomainPopup = async ({
    req,
}: {
    req: ServerRequest;
}): Promise<{ data: DomainPopup | null; error: Error | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: "/api/web/v2/popups",
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };

    const { data: domainPopupResponse, error } =
        await apiHandler<DomainPopupResponse>(() => HTTP.server(config));

    return {
        data: domainPopupResponse?.data ?? null,
        error,
    };
};
