/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosError, AxiosRequestConfig } from "axios";
import { RouteConfig } from "@page-builder/ts/types";
interface RouteConfigResponse {
    data: RouteConfig | null;
    success: boolean;
}

export const getRouteConfig = async ({
    req,
    slug,
}: {
    req: ServerRequest;
    slug: string;
}): Promise<{ data: RouteConfig | null; error: AxiosError | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v1/templates?uri=${slug}`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };
    const { data: routeConfigResponse, error } =
        await apiHandler<RouteConfigResponse>(() => HTTP.server(config));

    return {
        data: routeConfigResponse?.data ?? null,
        error,
    };
};
