import { getAccessToken, getLocale } from "@lib/shared/cookies";
import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface LookupAndVariationsResponse {
    success: boolean;
    data: LookupOrVariationItem[] | null;
}

export interface LookupOrVariationItem {
    id: number;
    label: string;
    value: string;
    icon: null;
}

export const getLookupsAndVariations = async ({
    req,
    source,
    queries = {
        orderBy: undefined,
        orderByDirection: undefined,
        lookupsId: undefined,
        variationsIds: undefined,
        type: undefined,
    },
}: {
    req?: ServerRequest;
    source: "lookups" | "variations";
    queries: {
        orderBy: "order" | "label" | undefined;
        orderByDirection: "asc" | "desc" | undefined;
        lookupsId: number | undefined;
        variationsIds: string | undefined;
        type: string | undefined;
    };
}): Promise<{ data: LookupOrVariationItem[] | null; error: Error | null }> => {
    const accessToken = req ? req.accessToken : getAccessToken();

    const config: AxiosRequestConfig = {
        method: "get",
        url: req ? `/api/web/v1/${source}` : `/api/data/${source}`,
        headers: {
            "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req ? req.locale ?? "en" : getLocale(),
        },
        params: req
            ? {
                  "filter[type]": queries.type,
                  "filter[variationIds]": queries.variationsIds,
                  "filter[lookupsId]": queries.lookupsId,
                  orderByField: queries.orderBy,
                  orderByDirection: queries.orderByDirection,
              }
            : queries,
    };
    if (req) {
        const { data: formResponse, error } =
            await apiHandler<LookupAndVariationsResponse>(() =>
                HTTP.server(config),
            );

        return {
            data: formResponse?.data ?? null,
            error,
        };
    } else {
        const { data: formResponse, error } =
            await apiHandler<LookupAndVariationsResponse>(() =>
                HTTP.client(config),
            );

        return {
            data: formResponse?.data ?? null,
            error,
        };
    }
};
