/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getAccessToken, getLocale } from "@lib/shared/cookies";
import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

export interface ZipCodeLocationResponse {
    success: boolean;
    data: ZipCodeLocation | null;
}

export interface ZipCodeLocation {
    zipCode: string;
    stateCode: string;
    city: string;
    county: string;
}

export const getLocationByZipCode = async ({
    req,
    zipCode,
}: {
    zipCode: string;
    req?: ServerRequest;
}): Promise<{
    data: ZipCodeLocation | null;
    error: Error | null;
}> => {
    const accessToken = req ? req.accessToken : getAccessToken();

    const config: AxiosRequestConfig = {
        method: "get",
        url: req
            ? `/api/web/v1/lookup/zipcode/${zipCode}`
            : `/api/zipCodeLookup/${zipCode}`,
        headers: {
            "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req ? req.locale ?? "en" : getLocale(),
        },
    };

    if (req) {
        const { data: zipCodeLocationResponse, error } =
            await apiHandler<ZipCodeLocationResponse>(() =>
                HTTP.server(config),
            );

        return {
            data: zipCodeLocationResponse?.data ?? null,
            error,
        };
    } else {
        const { data, error } = await apiHandler<ZipCodeLocationResponse>(() =>
            HTTP.client(config),
        );
        return {
            data: data ?? null,
            error,
        };
    }
};
