import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
interface Url {
    url: string;
}

interface AliasUrlResponse {
    success: boolean;
    data: Url | null;
}

export const getAliasUrl = async ({
    req,
    alias,
}: {
    req: ServerRequest;
    alias: string;
}): Promise<{ data: string | null; error: Error | null }> => {
    const url = new URL(
        `/shortener/api/v1/urls/aliases/${alias}`,
        process.env.BACKEND_URL,
    );

    const config: AxiosRequestConfig = {
        method: "get",
        url: `${url.pathname}${url.search}`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            "Content-Type": "application/json",
        },
    };

    const { data: aliasUrlResponse, error } =
        await apiHandler<AliasUrlResponse>(() => HTTP.server(config));

    return {
        data: aliasUrlResponse?.data?.url ?? null,
        error,
    };
};
