import { getAccessToken, setListIdCookie } from "@lib/shared/cookies";
import { ClickOffer, FilterQuestion, ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { ServerResponse } from "http";
import router from "next/router";

export interface ClicksSearchListResponse {
    success: boolean;
    data: ClicksSearchList | null;
}

export interface ClicksSearchList {
    listRequestId: string;
    result: ClickOffer[];
}

interface ClickLinkResponse {
    success: boolean;
    data: string | null;
}

const getQuestionsAsString = (questions: {
    [x: string]: Pick<FilterQuestion, "name" | "value">;
}) => {
    const initialValue = "";

    return Object.keys(questions).reduce(function (
        previousValue,
        currentValue,
        index,
    ) {
        const value = questions[currentValue].value;
        const name = questions[currentValue].name;

        if (!value) {
            return previousValue;
        }

        return (
            previousValue +
            `fields[${currentValue}]=${
                name === "birthDate" ? `${value}-01-01` : value
            }${index !== Object.keys(questions).length - 1 ? "&" : ""}`
        );
    },
    initialValue);
};

export const getClicksSearchList = async ({
    questions,
    categorySlug,
    crossSellingDomainFormId,
    zipCodeFallback = true,
    req,
    res,
}: {
    questions: { [x: string]: Pick<FilterQuestion, "name" | "value"> };
    categorySlug: string;
    crossSellingDomainFormId?: string | null;
    zipCodeFallback?: boolean;
    res?: ServerResponse;
    req?: ServerRequest;
}): Promise<{ data: ClicksSearchList | null; error: Error | null }> => {
    const filters = getQuestionsAsString(questions);

    const accessToken = req ? req.accessToken : (getAccessToken() as string);

    const headers: { [x: string]: string } = {
        "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
        Authorization: `Bearer ${accessToken ?? ""}`,
    };

    if (!req) {
        headers["fields"] = filters;
    }

    const config: AxiosRequestConfig = {
        method: "get",
        headers,
        url: req
            ? `/api/web/v2/clicks/search?${filters}&categorySlug=${categorySlug}&zipCodeFallback=${zipCodeFallback.toString()}${
                  crossSellingDomainFormId
                      ? `&domainFormId=${crossSellingDomainFormId}`
                      : ""
              }${
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  req.query.returnPrices
                      ? `&returnPrices=${
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            req.query.returnPrices as string
                        }`
                      : ""
              }`
            : `/api/search?categorySlug=${categorySlug}&zipCodeFallback=${zipCodeFallback.toString()}${
                  crossSellingDomainFormId
                      ? `&domainFormId=${crossSellingDomainFormId}`
                      : ""
              }${
                  router.query.returnPrices
                      ? `&returnPrices=${router.query.returnPrices as string}`
                      : ""
              }`,
    };

    if (req) {
        const { data: clicksSearchListResponse, error } =
            await apiHandler<ClicksSearchListResponse>(() =>
                HTTP.server(config),
            );

        if (!error && clicksSearchListResponse) {
            setListIdCookie(
                clicksSearchListResponse.data?.listRequestId ?? null,
                req,
                res,
            );
        }

        return { data: clicksSearchListResponse?.data ?? null, error };
    } else {
        const { data: clicksSearchListResponse, error } =
            await apiHandler<ClicksSearchListResponse>(() =>
                HTTP.client(config),
            );

        if (!error && clicksSearchListResponse) {
            setListIdCookie(
                clicksSearchListResponse.data?.listRequestId ?? null,
                req,
                res,
            );
        }

        return { data: clicksSearchListResponse?.data ?? null, error };
    }
};

export const getClickLink = async ({
    slug,
    listRequestId,
    req,
}: {
    slug: string;
    listRequestId: string;
    req: ServerRequest;
}): Promise<{ data: string | null; error: Error | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v1/clicks/${slug}/link?listRequestId=${listRequestId}`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken ?? ""}`,
        },
    };
    const { data: clickLinkResponse, error } =
        await apiHandler<ClickLinkResponse>(() => HTTP.server(config));

    return {
        data: clickLinkResponse?.data ?? null,
        error,
    };
};
