/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface callEventResponse {
    success: boolean;
    data: boolean;
}

export const callEvent = async ({
    event,
    phoneNumber,
}: {
    event: "Call" | "Subscribe" | "FirstFormInteraction";
    phoneNumber?: string;
}): Promise<{
    data: callEventResponse | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/callEvent`,
        data: {
            event,
            eventValue: phoneNumber,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: response, error } = await apiHandler<callEventResponse>(() =>
        HTTP.client(config),
    );

    return {
        data: response ?? null,
        error,
    };
};
