import { AffiliateOfferZone } from "@lib/shared/config";
import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface OfferUrlResponse {
    success: boolean;
    data: string | null;
}

export const getOfferUrl = async ({
    req,
    slug,
    listId,
    categoryId,
    affLinkId,
    zone,
}: {
    req: ServerRequest;
    slug: string;
    listId?: string | undefined;
    categoryId: number | undefined;
    affLinkId?: number | undefined;
    zone: AffiliateOfferZone;
}): Promise<{ data: string | null; error: Error | null }> => {
    const accessToken = req.accessToken;

    const url = new URL(
        `/api/web/v1/offers/${slug}/link`,
        process.env.BACKEND_URL,
    );

    if (listId) {
        url.searchParams.append("listId", listId);
    }

    if (categoryId) {
        url.searchParams.append("categoryId", categoryId.toString());
    }

    if (zone) {
        url.searchParams.append("zone", zone);
    }

    if (affLinkId) {
        url.searchParams.append("affLinkId", affLinkId.toString());
    }

    const config: AxiosRequestConfig = {
        method: "get",
        url: `${url.pathname}${url.search}`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: offerUrlResponse, error } =
        await apiHandler<OfferUrlResponse>(() => HTTP.server(config));

    return {
        data: offerUrlResponse?.data ?? null,
        error,
    };
};
