import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

export type DoNotSellFormResponse = {
    success: boolean;
    data: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        streetAddress: string;
        state: string;
        zipCode: string;
        doNotSell: boolean;
        deleteMyInfo: boolean;
        visitId: string;
        domainId: number;
    } | null;
    code?: string;
};

export const submitDoNotSellForm = async ({
    fields,
}: {
    fields: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        streetAddress: string;
        state: string;
        zipCode: string;
        doNotSell: boolean;
        deleteMyInfo: boolean;
        visitId: string;
        domainId: number;
    };
}): Promise<{ data: DoNotSellFormResponse | null; error: Error | null }> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/do-not-sell`,
        data: {
            ...fields,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: doNotSellFormResponse, error } =
        await apiHandler<DoNotSellFormResponse>(() => HTTP.client(config));

    return {
        data: doNotSellFormResponse,
        error,
    };
};
