/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface BlogPostResponse {
    success: boolean;
    data: { results: BlogPostType[] } | null;
}

export interface BlogPostType {
    id: number;
    url: string;
    title: string;
    model: {
        id: number;
        title: string;
        body: string | null;
        image: { fullPath: string };
        excerpt: string;
        tags: { name: string }[];
        category: { id: number; name: string; slug: string };
    };
}

export const getBlog = async ({
    req,
    slug,
}: {
    req: ServerRequest;
    slug: string[];
}): Promise<{ data: BlogPostType | null; error: Error | null }> => {
    const url = slug.join("/");

    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v1/domains/urls?url=/blog/${url}&includeModelInfo=true`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };

    const { data: urlResponse, error } = await apiHandler<BlogPostResponse>(
        () => HTTP.server(config),
    );

    return {
        data: urlResponse?.data?.results[0] ?? null,
        error,
    };
};
