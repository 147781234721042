import { Category, ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosError, AxiosRequestConfig } from "axios";

interface GenericRoutesResponse {
    success: boolean;
    data: GenericRoutes | null;
}

export interface GenericRoutes {
    categoryRoutes: Route[];
    domainRoutes: Route[];
}

export interface Route {
    id: number;
    route: string;
    isActive: boolean;
    isDeleted: boolean;
    ignoreKeyword: boolean;
    category: Category;
    title: string | null;
}

export const getGenericRoutes = async ({
    req,
}: {
    req: ServerRequest;
}): Promise<{ data: GenericRoutes | null; error: AxiosError | null }> => {
    const config: AxiosRequestConfig = {
        method: "get",
        url: "/api/web/v1/routes",
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            "Content-Type": "application/json",
        },
    };
    const { data: genericRoutesResponse, error } =
        await apiHandler<GenericRoutesResponse>(() => HTTP.server(config));

    return {
        data: genericRoutesResponse?.data ?? null,
        error,
    };
};

export const postNotFoundPage = async ({
    req,
}: {
    req: ServerRequest;
}): Promise<void> => {
    const config: AxiosRequestConfig = {
        method: "post",
        url: "/api/web/v1/routes/not-found",
        data: {
            //@ts-ignore
            route: req ? (req?.originalUrl as string) : "",
            ipAddress: `${
                req
                    ? process.env.NODE_ENV === "development"
                        ? (process.env.FALLBACK_IP_ADDRESS as string)
                        : (req.headers["cf-connecting-ip"] as string) ||
                          (req.headers["x-forwarded-for"] as string) ||
                          (req.socket.remoteAddress as string)
                    : ""
            }`,
            userAgent: req.headers["user-agent"]
                ? `${req.headers["user-agent"]}`
                : undefined,
            referrer: req?.headers?.referer,
            lang: req ? req.headers["accept-language"] : "",
        },
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
        },
    };

    await apiHandler(() => HTTP.server(config));
};
