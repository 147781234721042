import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface DKIsResponse {
    success: boolean;
    data: DKI[] | null;
}

export interface DKI {
    name: string;
    type:
        | "allowed"
        | "disallowed"
        | "ignored"
        | "disallowed_any"
        | "go_to_home";
}

export const getDKIs = async ({
    req,
}: {
    req: ServerRequest;
}): Promise<{ data: DKI[] | null; error: Error | null }> => {
    const config: AxiosRequestConfig = {
        method: "get",
        url: "/api/web/v1/dkis",
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
        },
    };

    const { data: DKIsResponse, error } = await apiHandler<DKIsResponse>(() =>
        HTTP.server(config),
    );

    return {
        data: DKIsResponse?.data ?? null,
        error,
    };
};
