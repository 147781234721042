/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
    getAccessToken,
    setAccessToken,
    setVisitorId,
    setLocaleCookie,
    setVisitId,
    getTestVariantId,
    setTestVariantId,
    getLanderCookie,
} from "@lib/shared/cookies";
import { ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { Options } from "@services/initial-calls";
import { AxiosError, AxiosRequestConfig } from "axios";
import { ServerResponse } from "http";
import { ParsedUrlQuery } from "querystring";

interface SessionResponse {
    success: boolean;
    data: Session;
}

export interface SessionTestConfig {
    config?: {
        css?: string;
        isDefault?: boolean;
    };
    variantId?: number;
}

export interface Session {
    accessToken: string | null;
    visitDetails: VisitDetails;
    testConfig?: SessionTestConfig;
}

export interface VisitDetails {
    country: string;
    countryCode: string;
    region: string;
    city: string;
    ip: string;
    postalCode: string | null;
    visitorId: string;
    userId: string;
    visitId: string;
    trafficSource: string | null;
    trafficSourceType: string | null;
    trafficSourceNetwork: string | null;
    platform: string;
    isMobile: boolean;
    hashedVisitId?: string;
}

export const getUserSession = async ({
    req,
    res,
    query,
    options,
}: {
    req: ServerRequest;
    res: ServerResponse;
    query?: ParsedUrlQuery;
    options?: Options;
}): Promise<{ data: Session | null; error: AxiosError | null }> => {
    const accessToken = req.query?.hashed
        ? undefined
        : getAccessToken(req, res);
    const variantId =
        req.query?.variantId ?? getTestVariantId("style", null, req, res);

    const cookieLander = getLanderCookie(req, res);
    const parsedVariantId = parseInt(variantId as string, 10);
    const lander = cookieLander ?? query?.lander ?? options?.lander ?? null;
    const meta: { lander?: string } = {};
    if (lander) {
        meta["lander"] = lander as string;
    }

    if (
        req &&
        (!req.headers["cf-connecting-ip"] ||
            req.headers["cf-connecting-ip"]?.includes("54.")) &&
        process.env.NODE_ENV !== "development"
    ) {
        // eslint-disable-next-line no-console
        console.log("----NoIP-----");
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(req.headers));
        // @ts-ignore
        const url = req.protocol + "://" + req.get("host") + req.originalUrl;
        // eslint-disable-next-line no-console
        console.log(url);
        // eslint-disable-next-line no-console
        console.log(req.headers);
        // eslint-disable-next-line no-console
        console.log("----/NoIP-----");
    }

    const config: AxiosRequestConfig = {
        method: "post",
        url: "/api/web/v1/sessions",
        data: {
            userAgent: req.headers["user-agent"] ?? null,
            ipAddress: `${
                req
                    ? process.env.NODE_ENV === "development"
                        ? (process.env.FALLBACK_IP_ADDRESS as string)
                        : (req.headers["cf-connecting-ip"] as string) ||
                          (req.headers["Cf-Connecting-Ip"] as string) ||
                          (req.headers["x-forwarded-for"] as string) ||
                          (req.socket.remoteAddress as string)
                    : ""
            }`,
            ...(req.query?.hashed ? { encryptedIp: req.query?.hashed } : {}),
            cookies: req ? req.headers.cookie : null,
            lang: req ? req.headers["accept-language"] : "",
            url: req
                ? //@ts-ignore
                  req.protocol + "://" + req.get("host") + req.originalUrl
                : null,
            referrer: req?.headers?.referer,
            variantId: !isNaN(parsedVariantId) ? parsedVariantId : undefined,
            meta,
        },
        headers: accessToken
            ? {
                  "X-DOMAIN-NAME": `${req ? req.headers.host ?? "" : ""}`,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken as string}`,
              }
            : {
                  "X-DOMAIN-NAME": `${req ? req.headers?.host ?? "" : ""}`,
                  "Content-Type": "application/json",
              },
    };

    const { data: sessionResponse, error } = await apiHandler<SessionResponse>(
        () => HTTP.server(config),
    );

    if (error) {
        return { error, data: null };
    }

    if (sessionResponse?.success) {
        // save it on the server
        req.accessToken = sessionResponse.data.accessToken;
        setAccessToken(sessionResponse.data.accessToken, req, res);
        setTestVariantId(
            "style",
            String(sessionResponse.data.testConfig?.variantId),
            null,
            req,
            res,
        );
        setVisitorId(sessionResponse.data.visitDetails.visitorId, req, res);
        setVisitId(sessionResponse.data.visitDetails.visitId, req, res);
        setLocaleCookie(req.locale ?? "en", req, res);
        return { data: sessionResponse.data, error: null };
    }

    return { data: null, error: null };
};
